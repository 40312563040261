<template>
    <b-checkbox @input="onChanged" v-model="status">{{ label }}</b-checkbox>
</template>

<script>
import axios from "axios";
export default {
    name: "NotificationCheckboxItem",
    props: {
        id: String,
        label: String,
    },
    data() {
        return {
            status: false,
            instance: null,
        };
    },
    mounted() {
        this.getNotificationStatus();
    },
    methods: {
        async getNotificationStatus() {
            //     const responses = (
            //         await Promise.all(
            //             list.map((i) => {
            //                 console.log(i);
            const response = await axios.get(
                this.$INFLUX_ALERT_ENDPOINT + "/api/v2/checks/" + this.id
            );
            const noti = response.data;
            if (noti.status === "active") {
                this.status = true;
            } else {
                this.status = false;
            }
            this.instance = noti;
            //             })
            //         )
            //     ).map((i) => i.data);
            //     // console.log({ responses });
            //     this.instance = responses;
            //     this.statusList = responses.map((i) => {
            //         if (i.state === "active") {
            //             return true;
            //         }
            //         return false;
            //     });
        },
        async updateNotificationStatus(status) {
            const s = status
            if (this.instance) {
                const response = await axios.put(
                    this.$INFLUX_ALERT_ENDPOINT + "/api/v2/checks/" + this.id,
                    {
                        ...this.instance,
                        status: s ? "active" : "inactive",
                    }
                );
                this.instance = response.data;
            }
        },
        onChanged(value) {
            console.log("onChanged:", value);
            this.updateNotificationStatus(value);
        },
    },
};
</script>
