<template>
    <div class="p-2">
        <div class="tile is-ancestor">
            <div class="tile is-6 is-parent">
                <value-badge
                    v-for="(item, index) in valueBadgeList"
                    :key="index"
                    :info="item"
                />
            </div>
            <div class="tile is-6 is-parent">
                <notification-checkbox
                    v-for="(item, index) in notificationList"
                    :key="index"
                    :info="item"
                />
            </div>
            <!-- <div class="tile is-parent"> -->
            <!-- </div> -->
        </div>
        <div class="columns is-multiline">
            <!-- <div class="tile is-6 is-parent">
                <value-badge
                    v-for="(item, index) in valueBadgeList"
                    :key="index"
                    :label="item.name"
                    :value="43.2"
                    unit="volt"
                />
            </div> -->
            <div
                class="column is-6 is-parent"
                v-for="(item, index) in lineChartList"
                :key="index"
            >
                <!-- <notification-checkbox
                    v-for="(item, index) in notificationList"
                    :key="index"
                /> -->
                <line-chart
                    :info="item"
                    :title="item.name"
                    :src="item.timeSeriesSource"
                />
            </div>
            <!-- <div class="tile"></div> -->
        </div>
    </div>
</template>

<script>
import ValueBadge from "@/components/ValueBadge.vue";
import LineChart from "@/components/LineChart.vue";
import NotificationCheckbox from "@/components/NotificationCheckbox.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        ValueBadge,
        LineChart,
        NotificationCheckbox,
    },
    name: "Dashboard",
    props: {
        id: String,
    },
    created() {
        console.log("created");
        this.$store.dispatch("dashboard/getDashboard", {
            id: this.id,
        });
    },
    computed: {
        ...mapGetters({
            valueBadgeList: "dashboard/valueBadgeList",
            notificationList: "dashboard/notificationList",
            lineChartList: "dashboard/lineChartList",
        }),
    },
    mounted() {
        console.log("mounted");
    },
    beforeDestroy(){
        this.$store.commit("dashboard/reset")
    }
};
</script>

<style scoped>
.tile.is-vertical > .tile.is-child:not(:last-child) {
    margin-bottom: 0.75rem !important;
}
</style>
