<template>
    <div class="box m-0 has-text-left is-flex-grow-1">
        <div class="title is-size-5">Notification</div>
        <div class="level is-mobile">
            <!-- <div> -->
            <b-field
                class="field"
                v-for="(item, index) in optionTypeCheckList"
                :key="index"
            >
                <!-- <b-checkbox :value="statusList[index]">{{
                    item.field
                }}</b-checkbox> -->
                <notification-checkbox-item :id="item.id" :label="item.field" />
            </b-field>
            <!-- </div> -->
        </div>
        <notification-checkbox-token
            :rules="rules"
            :id="lineTokenType.id"
        />
    </div>
</template>

<script>
// import axios from "axios";
import NotificationCheckboxItem from "./NotificationCheckboxItem.vue";
import NotificationCheckboxToken from "./NotificationCheckboxToken.vue";
export default {
    components: { NotificationCheckboxItem, NotificationCheckboxToken },
    name: "NotificationCheckbox",
    props: {
        info: Object,
    },
    data() {
        return {
            instance: [],
            statusList: [],
        };
    },
    computed: {
        parsedOption() {
            if (this.info) {
                return this.info.option.map((i) => JSON.parse(i));
            }
            return [];
        },
        optionTypeCheckList() {
            return this.parsedOption.filter((i) => i.type === "check");
        },
        lineTokenType() {
            return this.parsedOption.find((i) => i.type === "token");
        },
        rules() {
            return this.lineTokenType.rule;
        },
    },
    mounted() {
        // this.getNotificationStatus(this.optionTypeCheckList).catch((error) => {
        //     console.log(error);
        // });
    },
    methods: {
        onSave() {
            console.log("save");
        },
        // async getNotificationStatus(list) {
        //     const responses = (
        //         await Promise.all(
        //             list.map((i) => {
        //                 console.log(i);
        //                 return axios.get(
        //                     this.$INFLUX_ALERT_ENDPOINT +
        //                         "/api/v2/checks/" +
        //                         i.id
        //                 );
        //             })
        //         )
        //     ).map((i) => i.data);
        //     // console.log({ responses });
        //     this.instance = responses;
        //     this.statusList = responses.map((i) => {
        //         if (i.state === "active") {
        //             return true;
        //         }
        //         return false;
        //     });
        // },
    },
};
</script>

<style scoped>
.field {
    margin-bottom: 0.75rem !important;
}
</style>
