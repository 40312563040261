<template>
    <footer
        style="position: fixed;bottom: 0px;left:0px; right:0px"
        class="footer"
    >
        <div class="content has-text-centered">
            <p>
                © 2021 <strong>avilon robotics</strong>
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
};
</script>
