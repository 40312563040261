<template>
    <!-- <div class="tile is-child box m-0 columns is-mobile"> -->
    <!-- <div class="is-flex-grow-1"> -->
    <nav class="box is-flex-grow-1">
        <div class="has-text-left my-2">
            <strong>Status:</strong>
            {{ connected ? "Connected" : "Disconnected" }}
        </div>
        <div v-if="list.length" class="level is-mobile">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="level-item has-text-centered"
            >
                <div>
                    <p class="heading" style="text-transform: none;">
                        {{ item.name }}
                    </p>
                    <p class="title">{{ item.value }}</p>
                    <p
                        class="heading"
                        style="text-transform: none;min-height:16px;"
                    >
                        {{ item.unit }}
                    </p>
                </div>
            </div>
        </div>
        <div v-else class="level is-mobile is-flex-grow-1">
            Data is not available.
        </div>
        <!-- <div class="level-item has-text-centered">
                <div>
                    <p class="heading">DO</p>
                    <p class="title">123</p>
                    <p class="heading">volt</p>
                </div>
            </div>
            <div class="level-item has-text-centered">
                <div>
                    <p class="heading">pH</p>
                    <p class="title">456K</p>
                    <p class="heading">volt</p>
                </div>
            </div>
            <div class="level-item has-text-centered">
                <div>
                    <p class="heading">Temperature</p>
                    <p class="title">789</p>
                    <p class="heading">volt</p>
                </div>
            </div> -->
    </nav>

    <!-- </div> -->
    <!-- <div class="columns"> -->
    <!-- <div class="tile is-child  m-0 is-flex is-justify-content-center is-align-items-center">
                <p class="title">{{ label }}</p>
            </div>
            <div class="tile is-child m-0 is-flex is-justify-content-center is-align-items-center">
                <p class="title">{{ value }}</p>
            </div>
            <div class="tile is-child m-0 is-flex is-justify-content-center is-align-items-center">
                <p class="title">{{ unit }}</p>
            </div> -->
    <!-- </div> -->
    <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "ValueBadge",
    props: {
        info: Object,
    },
    data() {
        return {
            list: [],
            connected: false,
        };
    },
    computed: {
        ...mapGetters({
            messageByTopic: "mq/messageByTopic",
            messageCounter: "mq/messageCounter",
            connectivityCounter: "mq/connectivityCounter",
        }),
        options() {
            return this.info?.option || [];
        },
        parsedOptions() {
            return this.options.map((i) => JSON.parse(i));
        },
        badge() {
            const obj = this.parsedOptions.find((i) => {
                if (i.type === "Badge") {
                    return true;
                }
                return false;
            });
            // console.log(obj);
            // const data = this.messageByTopic(obj.topic) || {};
            // if (obj) {
            //     console.log({ data });
            //     // console.log({ obj });
            //     return obj.items.map((i) => {
            //         return {
            //             ...i,
            //             value: data[i.name],
            //         };
            //     });
            // }
            return obj || {};
        },
        connectivity() {
            const obj = this.parsedOptions.find((i) => {
                if (i.type === "Connectivity") {
                    return true;
                }
                return false;
            });
            return obj || {};
        },
    },
    watch: {
        messageCounter() {
            const badge = this.badge;
            if (badge) {
                const data = this.messageByTopic(badge.topic) || {};
                // console.log({ data });
                this.list = badge.items.map((i) => {
                    return {
                        ...i,
                        value:
                            Math.round((data[i.name] + Number.EPSILON) * 100) /
                            100,
                    };
                });
            }
        },
        connectivityCounter() {
            if (this.connectivity.thingID) {
                this.connected =
                    this.$store.getters["mq/connectivity"](
                        this.connectivity.thingID
                    ) || false;
            }
        },
    },
    mounted() {
        this.$store.dispatch("mq/MqttSubscribe", this.connectivity.topic);
        // console.log("info:", this.info);
    },
    methods: {},
};
</script>
