







































import Vue from "vue";
export default Vue.extend({
    name: "TopBar",
    methods: {
        onSignout() {
            this.$emit("on-signout");
        },
    },
});
