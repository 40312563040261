<template>
    <div class="level">
        <b-field label="LineToken">
            <b-input type="password" v-model="token"></b-input>
        </b-field>
        <b-button @click="onSave">Save</b-button>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "NotificationCheckboxToken",
    props: {
        id: String,
        rules: Array,
    },
    data() {
        return {
            token: null,
            instance: null,
        };
    },
    mounted() {
        this.getToken();
    },
    methods: {
        async onSave() {
            console.log("onSave");
            if (this.instance) {
                let instance = { ...this.instance };
                instance.url =
                    "https://b256q6s0cl.execute-api.ap-southeast-1.amazonaws.com/queryToken?token=" +
                    this.token;
                const response = await axios.put(
                    this.$INFLUX_ALERT_ENDPOINT +
                        "/api/v2/notificationEndpoints/" +
                        this.id,
                    instance
                );

                const res = await Promise.all(
                    this.rules.map(async (item) => {
                        let response = await axios.get(
                            this.$INFLUX_ALERT_ENDPOINT +
                                "/api/v2/notificationRules/" +
                                item
                        );
                        let instance = response.data;
                        console.log({ instance });
                        instance.status = "inactive";
                        response = await axios.put(
                            this.$INFLUX_ALERT_ENDPOINT +
                                "/api/v2/notificationRules/" +
                                item,
                            instance
                        );
                        instance = response.data;
                        instance.status = "active";
                        instance = await axios.put(
                            this.$INFLUX_ALERT_ENDPOINT +
                                "/api/v2/notificationRules/" +
                                item,
                            instance
                        );
                        return;
                    })
                );
                console.log({ res });
                console.log({ response });
            }
        },
        async getToken() {
            const response = await axios.get(
                this.$INFLUX_ALERT_ENDPOINT +
                    "/api/v2/notificationEndpoints/" +
                    this.id
            );
            const { data } = response;
            const { url } = data;
            console.log({ url });
            const token = url.replace(
                "https://b256q6s0cl.execute-api.ap-southeast-1.amazonaws.com/queryToken?token=",
                ""
            );
            this.instance = data;
            console.log({ token });
            this.token = token;
        },
    },
};
</script>
