<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Download {{ title }} data</p>
        </header>
        <section class="modal-card-body">
            <b-field label="Select a date">
                <b-datepicker
                    placeholder="Click to select..."
                    v-model="dates"
                    range
                    inline
                >
                </b-datepicker>
            </b-field>
            <b-field label="Select interval">
                <b-select multiple native-size="3" v-model="selectedInterval">
                    <option value="10m">every 10 minutes</option>
                    <option value="1h">every hour</option>
                    <option value="3h">every 3 Hours</option>
                </b-select>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button label="Close" @click="$parent.close()" />
            <b-button
                :disabled="disabled"
                label="Download"
                :loading="loading"
                type="is-primary"
                @click="onDownload"
            />
        </footer>
    </div>
</template>

<script>
import dayjs from "dayjs";
import def, { Parser } from "json2csv";
import { saveAs } from "file-saver";
console.log("stringExcelFormatter", def);
const queryTemplate = `from(bucket: "AVILON_IOT")
                  |> range(start: $start, stop: $stop)
                  |> filter(fn: (r) => r["_measurement"] == "$clientid")
                  |> filter(fn: (r) => r["$tag1"] == "$serial")
                  |> filter(fn: (r) => r["_field"] == "$field")
                  |> aggregateWindow(
                    every: $interval,
                    fn: mean,
                    createEmpty: true
                  )`;

export default {
    name: "LineChartDownloadForm",
    props: {
        title: String,
        clientid: String,
        serial: String,
        field: String,
        src: Array,
    },
    data() {
        return {
            dates: [],
            loading: false,
            selectedInterval: ["1h"],
            disabled: true,
        };
    },
    watch: {
        dates(newValue) {
            if (newValue.length >= 2) {
                this.disabled = false;
                return;
            }
            this.disabled = true;
            return;
        },
    },
    methods: {
        async query(clientid, serial, tag1, field) {
            let q = queryTemplate;
            q = q.replace("$clientid", clientid);
            q = q.replace("$tag1", tag1);
            q = q.replace("$serial", serial);
            q = q.replace("$field", field);
            q = q.replace("$start", dayjs(this.dates[0]).unix());
            q = q.replace(
                "$stop",
                dayjs(this.dates[1])
                    .add(24, "hours")
                    .unix()
            );
            q = q.replace("$interval", this.selectedInterval[0]);
            let response = await this.$QUERY_API.collectRows(q);
            const series = response.map((i) => {
                let d = {
                    date: dayjs(i._time).format("DD-MM-YYYY"),
                    time: dayjs(i._time).format("HH:mm"),
                };
                d[this.title] = i._value;
                return d;
            });
            this.series = series;
            return series;
        },
        async onDownload() {
            console.log("onDownload", this.dates);
            try {
                this.loading = true;
                const series = await this.query(
                    this.src[0],
                    this.src[0],
                    this.src[1],
                    this.title
                );
                console.log("series:", series);
                const fields = ["date", "time", this.title];
                const opts = { fields, quote: "" };
                const parser = new Parser(opts);
                const csv = parser.parse(series);
                console.log(csv);
                var blob = new Blob([csv], {
                    type: "text/plain;charset=utf-8",
                });
                saveAs(
                    blob,
                    `${this.title}_${dayjs(this.dates[0]).format(
                        "DD-MM-YYYY"
                    )}_${dayjs(this.dates[1]).format("DD-MM-YYYY")}.csv`
                );
            } catch (error) {
                console.log("error:", error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
