<template>
    <div class="box">
        <div class="tile is-ancestor is-vertical">
            <nav class="level is-mobile">
                <div class="level-left">
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="title">{{ title }}</p>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item has-text-centered">
                        <b-button
                            @click="setMode('minute')"
                            size="is-small"
                            :class="
                                `mx-1 ${mode === 'minute' ? 'is-primary' : ''}`
                            "
                            rounded
                            >minute</b-button
                        >
                    </div>
                    <div class="level-item has-text-centered">
                        <b-button
                            @click="setMode('hour')"
                            size="is-small"
                            :class="
                                `mx-1 ${mode === 'hour' ? 'is-primary' : ''}`
                            "
                            rounded
                            >hour</b-button
                        >
                    </div>
                    <div class="level-item has-text-centered">
                        <b-button
                            @click="setMode('day')"
                            size="is-small"
                            :class="
                                `mx-1 ${mode === 'day' ? 'is-primary' : ''}`
                            "
                            rounded
                            >day</b-button
                        >
                    </div>
                    <div class="level-item has-text-centered">
                        <!-- <b-button
                            @click="save"
                            size="is-small"
                            :class="
                                `mx-1 ${mode === 'day' ? 'is-primary' : ''}`
                            "
                            rounded
                            >save</b-button
                        > -->
                        <b-button
                            @click="save"
                            size="is-small"
                            icon-right="upload"
                        />
                        <b-button
                            @click="onDownload"
                            size="is-small"
                            icon-right="download"
                        />
                        <!-- <b-modal
                            v-model="isDownloadFormActive"
                            has-modal-card
                            full-screen
                            trap-focus
                            :can-cancel="false"
                            :destroy-on-hide="true"
                            aria-role="dialog"
                            aria-label="Example Modal"
                            aria-modal
                        >
                            <template #default="props">
                                <line-chart-download-form @close="props.close">
                                </line-chart-download-form>
                            </template>
                        </b-modal> -->
                        <b-modal
                            v-model="isDownloadFormActive"
                            has-modal-card
                            full-screen
                            :can-cancel="false"
                        >
                            <line-chart-download-form
                                :title="title"
                                :clientid="clientid"
                                :field="field"
                                :serial="serial"
                                :src="src"
                            />
                            <!-- <modal-form v-bind="formProps"></modal-form> -->
                        </b-modal>
                    </div>
                </div>
            </nav>
            <div class="level">
                <div class="level-item" style="height:100%; min-height: 150px">
                    <div ref="chart" :style="chartStyle" />
                </div>
                <div class="level-right">
                    <div class="level-item" style="flex-direction:column">
                        <div class="is-block p-2">
                            <p class="is-size-7">Upper bound</p>
                            <b-input
                                type="number"
                                size="is-small"
                                placeholder="0.0"
                                v-model="upperBound"
                            ></b-input>
                        </div>
                        <div class="is-block p-2">
                            <p class="is-size-7">Lower bound</p>
                            <b-input
                                type="number"
                                size="is-small"
                                placeholder="0.0"
                                v-model="lowerBound"
                            ></b-input>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style="justify-content: flex-start;display:flex;flex-direction:row-reverse"
            >
                <b-button
                    v-if="!isShowTable"
                    size="is-small"
                    @click="isShowTable = true"
                    >Show table</b-button
                >
                <b-button
                    v-if="isShowTable"
                    size="is-small"
                    @click="isShowTable = false"
                    >Hide table</b-button
                >
            </div>
            <div class="level">
                <b-table
                    v-if="isShowTable"
                    style="width:100%"
                    header-class="header-table"
                    :mobile-cards="false"
                    :data="tableData"
                    :paginated="true"
                    :per-page="10"
                    :row-class="RowClass"
                >
                    <b-table-column
                        field="date"
                        label="Date"
                        :td-attrs="columnTdAttrs"
                        v-slot="props"
                        centered
                    >
                        {{ props.row.date }}
                    </b-table-column>

                    <b-table-column
                        field="time"
                        label="Time"
                        :td-attrs="columnTdAttrs"
                        v-slot="props"
                        centered
                    >
                        {{ props.row.time }}
                    </b-table-column>

                    <b-table-column
                        :field="title"
                        :label="title"
                        :td-attrs="columnTdAttrs"
                        v-slot="props"
                        centered
                    >
                        {{ props.row[title] }}
                    </b-table-column>

                    <b-table-column
                        field="change"
                        label="Change"
                        :td-attrs="columnTdAttrs"
                        v-slot="props"
                        centered
                    >
                        <span
                            :style="
                                props.row.sign < 0 ? `color:red` : `color:green`
                            "
                        >
                            <!-- <b-icon

                                pack="fas"
                                :icon="props.row.sign > 0 ? 'mars' : 'venus'"
                            >
                            </b-icon> -->
                            {{ props.row.change }}
                        </span>
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
// console.log({ echarts });

const queryTemplate = {
    minute: `from(bucket: "AVILON_IOT")
                  |> range(start: -1h)
                  |> filter(fn: (r) => r["_measurement"] == "$clientid")
                  |> filter(fn: (r) => r["$tag1"] == "$serial")
                  |> filter(fn: (r) => r["_field"] == "$field")
                  |> aggregateWindow(
                    every: 1m,
                    fn: mean,
                    createEmpty: true
                  )`,
    hour: `from(bucket: "AVILON_IOT")
                  |> range(start: -24h)
                  |> filter(fn: (r) => r["_measurement"] == "$clientid")
                  |> filter(fn: (r) => r["$tag1"] == "$serial")
                  |> filter(fn: (r) => r["_field"] == "$field")
                  |> aggregateWindow(
                    every: 1h,
                    fn: mean,
                    createEmpty: true
                  )`,
    day: `from(bucket: "AVILON_IOT")
                  |> range(start: -72h)
                  |> filter(fn: (r) => r["_measurement"] == "$clientid")
                  |> filter(fn: (r) => r["$tag1"] == "$serial")
                  |> filter(fn: (r) => r["_field"] == "$field")
                  |> aggregateWindow(
                    every: 3h,
                    fn: mean,
                    createEmpty: true
                  )`,
};

const intervalTemplate = {
    minute: 15000,
    hour: 60000,
    day: 300000,
};
import axios from "axios";
import LineChartDownloadForm from "./LineChartDownloadForm.vue";
export default {
    components: { LineChartDownloadForm },
    name: "LineChart",
    props: {
        title: String,
        clientid: String,
        serial: String,
        field: String,
        src: Array,
        info: Object,
    },
    data() {
        return {
            h: 100,
            w: 100,
            resizeObserver: null,
            mode: "minute",
            fetcher: null,
            chart: null,
            upperBound: null,
            lowerBound: null,
            series: null,
            isShowTable: true,
            isDownloadFormActive: false,
            // checkId: null,
        };
    },
    computed: {
        column() {
            return [
                {
                    field: "date",
                    label: "Date",
                    centered: true,
                },
                {
                    field: "time",
                    label: "Time",
                    centered: true,
                },
                {
                    field: this.title,
                    label: this.title,
                    centered: true,
                },
                {
                    field: "change",
                    label: "change",
                    centered: true,
                },
            ];
        },
        tableData() {
            return this.series
                ?.slice()
                .reverse()
                .map((item, index, self) => {
                    let x = {};
                    x.date = dayjs.unix(item[0] / 1000).format("DD/MM/YYYY");
                    x.time = dayjs.unix(item[0] / 1000).format("H:mm:ss");
                    x[this.title] = item[1]?.toFixed(2);
                    if (x[this.title]) {
                        x.change = (
                            x[this.title] -
                            (self[index + 1] ? self[index + 1][1] : 0)
                        ).toFixed(2);
                        x.sign = Math.sign(x.change);
                        x.change = `${x.sign > 0 ? "+" : ""}${x.change}`;
                    }
                    x.id = index;
                    // x.change = x[this.title] - (self[index-1] ? self)
                    return x;
                });
            // return [
            //     {
            //         id: 1,
            //         first_name: "Jesse",
            //         last_name: "Simmons",
            //         date: "2016-10-15 13:43:27",
            //         gender: "Male",
            //     },
            //     {
            //         id: 2,
            //         first_name: "John",
            //         last_name: "Jacobs",
            //         date: "2016-12-15 06:00:53",
            //         gender: "Male",
            //     },
            //     {
            //         id: 3,
            //         first_name: "Tina",
            //         last_name: "Gilbert",
            //         date: "2016-04-26 06:26:28",
            //         gender: "Female",
            //     },
            //     {
            //         id: 4,
            //         first_name: "Clarence",
            //         last_name: "Flores",
            //         date: "2016-04-10 10:28:46",
            //         gender: "Male",
            //     },
            //     {
            //         id: 5,
            //         first_name: "Anne",
            //         last_name: "Lee",
            //         date: "2016-12-06 14:38:38",
            //         gender: "Female",
            //     },
            // ];
        },
        chartStyle() {
            return `width: 100%;height: 100%;min-height:150px`;
        },
        parsedOptions() {
            if (this.info.option) {
                return this.info.option.map((i) => JSON.parse(i));
            }
            return [];
        },
        checkId() {
            const opt = this.parsedOptions.find((i) => i.type === "checks");
            if (opt) {
                return opt.id;
            }
            return null;
        },
    },
    watch: {
        mode() {
            this.clearFetcher();
            this.createFetcher();
        },
        lowerBound() {
            this.draw(this.series);
        },
        upperBound() {
            this.draw(this.series);
        },
    },
    created() {
        console.log("line chart created");
    },
    mounted() {
        this.loadOption();
        this.createChart();
        this.createFetcher();
        //     // this.draw();
    },
    beforeDestroy() {
        this.clearFetcher();
    },
    methods: {
        loadOption() {
            if (this.info.option) {
                const parsed = this.info.option.map((i) => JSON.parse(i));
                console.log({ parsed });
                this.dispatchOption(parsed);
            }
        },
        dispatchOption(list) {
            list.map((item) => {
                switch (item.type) {
                    case "upperBound":
                        this.upperBound = item.value;
                        break;
                    case "lowerBound":
                        this.lowerBound = item.value;
                        break;
                    default:
                        break;
                }
            });
        },
        RowClass(row, index) {
            if (index === 0) {
                // return "is-alert";
                if (row[this.title] > parseFloat(this.upperBound)) {
                    return "is-alert";
                }
                if (row[this.title] < parseFloat(this.lowerBound)) {
                    return "is-alert";
                }
            }
            return null;
        },
        columnTdAttrs(row, column) {
            if (row.id === "Total") {
                if (column.label === "ID") {
                    return {
                        colspan: 4,
                        class: "has-text-weight-bold",
                        style: {
                            "text-align": "left !important",
                        },
                    };
                } else if (column.label === "Gender") {
                    return {
                        class: "has-text-weight-semibold",
                    };
                } else {
                    return {
                        style: { display: "none" },
                    };
                }
            }
            return null;
        },
        async save() {
            let option = [];
            if (this.upperBound) {
                option.push(
                    JSON.stringify({
                        type: "upperBound",
                        value: this.upperBound,
                    })
                );
            }
            if (this.lowerBound) {
                option.push(
                    JSON.stringify({
                        type: "lowerBound",
                        value: this.lowerBound,
                    })
                );
            }
            if (this.checkId) {
                option.push(
                    JSON.stringify({
                        type: "checks",
                        id: this.checkId,
                    })
                );
            }

            console.log({ option });

            await this.$store.dispatch("dashboard/updateItem", {
                ...this.info,
                option,
            });

            let response = await axios.get(
                this.$INFLUX_ALERT_ENDPOINT + "/api/v2/checks/" + this.checkId
            );
            let instance = response.data;
            console.log({ instance });

            instance.status = "inactive";
            instance.thresholds = instance.thresholds.map((i) => {
                if (i.type === "lesser") {
                    return {
                        ...i,
                        value: parseInt(this.lowerBound),
                    };
                }
                if (i.type === "greater") {
                    return {
                        ...i,
                        value: parseInt(this.upperBound),
                    };
                }
            });

            console.log({ instance });

            response = await axios.put(
                this.$INFLUX_ALERT_ENDPOINT + "/api/v2/checks/" + this.checkId,
                instance
            );

            console.log({ response });
            instance.status = "active";
            response = await axios.put(
                this.$INFLUX_ALERT_ENDPOINT + "/api/v2/checks/" + this.checkId,
                instance
            );
            console.log({ response });
        },
        createChart() {
            let myChart = echarts.init(this.$refs.chart);
            this.resizeObserver = new ResizeObserver((entries) => {
                // console.log("Size changed", entries[0].contentRect);
                let { width, height } = entries[0].contentRect;
                this.w = width;
                this.h = height;
                myChart.resize();
                //   entries[0].contentRect
            });
            this.resizeObserver.observe(this.$refs.chart);
            this.chart = myChart;
        },
        createFetcher() {
            this.query(this.src[0], this.src[0], this.src[1], this.title);
            this.fetcher = setInterval(() => {
                this.query(this.src[0], this.src[0], this.src[1], this.title);
            }, intervalTemplate[this.mode]);
        },
        clearFetcher() {
            if (this.fetcher) {
                clearInterval(this.fetcher);
            }
        },
        async query(clientid, serial, tag1, field) {
            // console.log("query");
            let q = queryTemplate[this.mode];
            q = q.replace("$clientid", clientid);
            q = q.replace("$tag1", tag1);
            q = q.replace("$serial", serial);
            q = q.replace("$field", field);
            let response = await this.$QUERY_API.collectRows(q);
            // console.log({ response });
            const series = response.map((i) => {
                return [dayjs(i._time).unix() * 1000, i._value];
            });
            // console.log({ series });
            this.series = series;
            this.draw(series);
        },
        setMode(val) {
            this.mode = val;
        },
        draw(series) {
            let upperMarker = [
                {
                    yAxis: this.upperBound,
                    x: "90%",
                    symbol: "none",
                },
                {
                    yAxis: this.upperBound,
                    x: "10%",
                    symbol: "none",
                },
            ];

            let lowerMarker = [
                {
                    yAxis: this.lowerBound,
                    x: "90%",
                    symbol: "none",
                },
                {
                    yAxis: this.lowerBound,
                    x: "10%",
                    symbol: "none",
                },
            ];

            let markerData = [];

            if (this.upperBound) {
                markerData.push(upperMarker);
            }
            if (this.lowerBound) {
                markerData.push(lowerMarker);
            }

            let option = {
                // title: {
                //     text: "",
                // },
                tooltip: {
                    trigger: "axis",
                },
                xAxis: {
                    type: "time",
                    boundaryGap: false,
                    // axisLabel: {
                    //     interval: 0,
                    //     // rotate: -90,
                    // },
                    // data: series.map((i) => i.x),
                },
                yAxis: {
                    type: "value",
                },
                grid: { top: "15%", bottom: "15%", left: "10%" },
                series: [
                    {
                        type: "line",
                        // data: [0.83, 0.32, 0.75, 0.66, 0.77, 0.71, 0.79],
                        data: series,
                        itemStyle: { normal: { label: { show: false } } },
                        markLine: {
                            itemStyle: {
                                normal: {
                                    borderWidth: 1,
                                    lineStyle: {
                                        type: "dash",
                                        color: "#FF0000 ",
                                        width: 2,
                                    },
                                    label: {
                                        textStyle: {
                                            fontSize: 16,
                                            fontWeight: "bolder",
                                        },
                                    },
                                },
                            },
                            data: markerData,
                            // data: [
                            //     // {
                            //     //     name: "average line",
                            //     //     // 'average', 'min', and 'max' are supported
                            //     //     type: "average",
                            //     // },
                            //     [
                            //         {
                            //             // Mark line with a fixed X position in starting point. This is used to generate an arrow pointing to maximum line.
                            //             yAxis: 0.901,
                            //             x: "90%",
                            //             symbol: "none",
                            //         },
                            //         {
                            //             yAxis: 0.901,
                            //             x: "10%",
                            //             symbol: "none",
                            //         },
                            //     ],
                            //     // {
                            //     //     name: "报警预测值",
                            //     //     yAxis: 0.901,
                            //     // },
                            //     // {
                            //     //     name: "报警预测值",
                            //     //     yAxis: 0.101,
                            //     //     symbol: "none",
                            //     // },
                            //     // [
                            //     //     // start point of the line
                            //     //     // we have to defined line attributes only here (not in the end point)
                            //     //     {
                            //     //         xAxis: 1509762600,
                            //     //         yAxis: 0.101,
                            //     //         symbol: "none",
                            //     //         // lineStyle: {
                            //     //         //     normal: {
                            //     //         //         color: "#00F",
                            //     //         //     },
                            //     //         // },
                            //     //         // label: {
                            //     //         //     normal: {
                            //     //         //         show: true,
                            //     //         //         position: "end",
                            //     //         //         formatter: "my label",
                            //     //         //     },
                            //     //         // },
                            //     //     },
                            //     //     // end point of the line
                            //     //     {
                            //     //         xAxis: 1509849000,
                            //     //         yAxis: 0.101,
                            //     //         symbol: "none",
                            //     //     },
                            //     // ],
                            // ],
                        },
                    },
                ],
            };
            if (this.chart) {
                this.chart.setOption(option);
            }
        },
        onDownload() {
            console.log("onDownload");
            this.isDownloadFormActive = !this.isDownloadFormActive;
        },
    },
    // beforeDestroy(){
    //     this.resizeObserver.
    // }
};
</script>

<style scoped>
.border-red {
    border: 1px solid red;
}
.header-table {
    border: 1px solid green;
}
</style>

<style>
tr.is-alert {
    background-color: rgba(255, 56, 56, 0.25);
    /* border: 5px solid rgb(255, 126, 56); */
}
</style>
