<template>
    <div class="about">
        <TopBar @on-signout="onSignout" />
        <vue-tabs-chrome ref="tabs" v-model="tab" :tabs="tabs" />
        <component
            :is="currentTabType"
            :id="currentTabId"
            :key="currentTabId"
            @on-open-dashboard="onOpenDashboard"
        />
        <div class="p-5" />
        <Footer />
        <!-- <h1>This is an about page</h1> -->
    </div>
</template>

<script>
import Vue from "vue";
import VueTabsChrome from "vue-tabs-chrome";
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import Console from "@/views/SmartTabs/Console";
import Dashboard from "@/views/SmartTabs/Dashboard";
import Auth from "@aws-amplify/auth";
import { mapGetters } from "vuex";
import DashboardV2 from "@/views/SmartTabs/DashboardV2";
export default Vue.extend({
    components: {
        TopBar,
        VueTabsChrome,
        Footer,
        Console,
        Dashboard,
        DashboardV2,
    },
    data() {
        return {
            tab: "Console",
            tabs: [
                {
                    label: "Console",
                    key: "Console",
                    closable: false,
                    componentType: "Console",
                    // favicon: require("./assets/google.jpg"),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            thingList: "user/thingList",
            isConnected: "mq/isConnected",
        }),
        currentTab() {
            return this.tabs.find((i) => i.key === this.tab);
        },
        currentTabId() {
            return this.currentTab.key;
        },
        currentTabType() {
            return this.currentTab.componentType;
        },
    },
    watch: {
        thingList(list) {
            console.log("thing list:", list);
            if (this.isConnected) {
                list.map((i) => {
                    console.log("sub: ", i.thingID);
                    const topic = i.thingID + "/data/raw";
                    this.$store.dispatch("mq/MqttSubscribe", topic);
                    // const topicRaw = i.thingID + "/data/raw";
                    // console.log("subscribe: ", topicRaw)
                    // this.$store.dispatch("mq/MqttSubscribe", topicRaw);
                });
            }
        },
        isConnected(connected) {
            console.log("isConnected:", connected);
            if (connected) {
                this.thingList.map((i) => {
                    console.log("sub: ", i.thingID);
                    const topic = i.thingID + "/data/raw";
                    this.$store.dispatch("mq/MqttSubscribe", topic);
                });
            }
        },
    },
    created() {
        this.fetchUser();
        this.mqttConfig();
    },
    methods: {
        async mqttConfig() {
            await this.$store.dispatch("mq/MqttConnect", {
                broker_url: this.$MQTT,
                auth_url: this.$THING_CREDENTIAL,
            });
        },
        async fetchUser() {
            const user = this.$store.getters["auth/user"];
            const { username, attributes } = user;
            const { email } = attributes;
            const result = await this.$store.dispatch("user/fetchUser", {
                username,
                name: email,
            });
            await this.$store.commit("user/setUser", result);
        },
        async onSignout() {
            this.$store.commit("auth/reset");
            await Auth.signOut();
            this.$router.replace("/auth");
        },
        onOpenDashboard(dashboard) {
            console.log("open:", { dashboard });
            const nTab = [...this.tabs];
            const index = nTab.findIndex((i) => i.key === dashboard.id);
            if (index < 0) {
                this.$refs.tabs.addTab({
                    label: dashboard.name,
                    key: dashboard.id,
                    componentType: dashboard.type || "Dashboard",
                });
                this.tab = dashboard.id;
                // nTab.push({
                //     label: dashboard.name,
                //     key: dashboard.id,
                //     componentType: "Dashboard",
                // });
                // this.tabs = nTab;
            } else {
                this.tab = dashboard.id;
            }
        },
    },
});
</script>
