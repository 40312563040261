<template>
    <div>
        <section class="hero is-small is-primary has-text-left">
            <div class="hero-body">
                <p class="title is-4">
                    My dashboards
                </p>
                <div class="columns">
                    <div
                        v-for="(item, index) in dashboardList"
                        :key="index"
                        class="column is-narrow"
                    >
                        <div class="box level">
                            <p class="title is-5 has-text-black mx-1">
                                {{ item.name }}
                            </p>
                            <b-button
                                @click="onOpenDashboard(item)"
                                type="is-primary"
                                size="is-small"
                                icon-right="external-link-alt"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Console",
    data() {
        return {
            data: [
                {
                    id: 1,
                    first_name: "Jesse",
                    last_name: "Simmons",
                    date: "2016-10-15 13:43:27",
                    gender: "Male",
                },
                {
                    id: 2,
                    first_name: "John",
                    last_name: "Jacobs",
                    date: "2016-12-15 06:00:53",
                    gender: "Male",
                },
                {
                    id: 3,
                    first_name: "Tina",
                    last_name: "Gilbert",
                    date: "2016-04-26 06:26:28",
                    gender: "Female",
                },
                {
                    id: 4,
                    first_name: "Clarence",
                    last_name: "Flores",
                    date: "2016-04-10 10:28:46",
                    gender: "Male",
                },
                {
                    id: 5,
                    first_name: "Anne",
                    last_name: "Lee",
                    date: "2016-12-06 14:38:38",
                    gender: "Female",
                },
            ],
            columns: [
                {
                    field: "id",
                    label: "ID",
                    width: "100",
                    numeric: true,
                    // searchable: true,
                },
                {
                    field: "first_name",
                    label: "Name",
                    searchable: true,
                },
                // {
                //     field: "last_name",
                //     label: "Last Name",
                //     searchable: true,
                // },
                // {
                //     field: "date",
                //     label: "Date",
                //     centered: true,
                // },
                // {
                //     field: "gender",
                //     label: "Gender",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters({
            dashboardList: "user/dashboardList",
        }),
    },
    methods: {
        onOpenDashboard(dashboard) {
            this.$emit("on-open-dashboard", dashboard);
        },
    },
};
</script>
